module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"glance-digital","short_name":"glance","start_url":"/","background_color":"#FFEAD4","theme_color":"#1049C5","display":"minimal-ui","icon":"src/images/glance-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":240,"linkImagesToOriginal":false,"quality":60,"withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-76128836-1","head":true,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-analytics/gatsby-browser.js'),
      options: {"plugins":[],"appId":"499276414066846","version":"v6.0","xfbml":false,"cookie":false,"includeInDevelopment":false,"debug":false,"language":"en_US"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
